import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Logo } from '../logo';
import { PhrasesBox } from '../Phrases';
import { Pop } from '../dop/pop';
import {
  Check,
  CopyAllOutlined,
  CurrencyRuble,
  ErrorOutline,
  KeyboardArrowDown,
  Percent,
} from '@mui/icons-material';
import { calculateDiscountedPrice, copyToBuffer } from '../../helpers';
import { CalcSelect } from '../calc-select';

const steps = {
  1: 'Начальные данные',
  2: 'Первичный учет',
  3: 'Расчет заработной платы',
  4: 'Кадровое делопроизводство',
  5: 'Отчетность для клиента',
  6: 'Синхронизация баз',
  7: 'ККМ',
};

const TariffCalculator = ({ phrases }) => {
  // Начальные данные состояния
  const [opf, setOpf] = useState('ИП');
  const [sno, setSno] = useState('УСН Д-Р');
  const [ved, setVed] = useState(false);
  const [op, setOp] = useState(0);
  const [not1CBP, setNot1CBP] = useState(false);
  const [not1CZUP, setNot1CZUP] = useState(false);
  const [workInBP, setWorkInBP] = useState(false);
  const [workInZUP, setWorkInZUP] = useState(false);
  const [employeeComplexity, setEmployeeComplexity] = useState(1);
  const [militaryRegistration, setMilitaryRegistration] = useState(false);
  const [militaryRegistrationComplexity, setMilitaryRegistrationComplexity] = useState(1);
  const [militaryServiceComplexity, setMilitaryServiceComplexity] = useState(1);
  const [employees, setemployees] = useState(0);
  const [migrationRegistration, setMigrationRegistration] = useState(false);
  const [migrationRegistrationComplexity, setMigrationRegistrationComplexity] = useState(1);
  const [migrationServiceEmployees, setMigrationServiceEmployees] = useState(0);
  const [reporting, setReporting] = useState(false);
  const [reportingComplexity, setReportingComplexity] = useState(1);
  const [ddsReport, setDdsReport] = useState(false);
  const [ddsComplexity, setDdsComplexity] = useState(1);
  const [paymentCalendar, setPaymentCalendar] = useState(false);
  const [paymentCalendarComplexity, setPaymentCalendarComplexity] = useState(1);
  const [syncBases, setSyncBases] = useState(false);
  const [syncBasesComplexity, setSyncBasesComplexity] = useState(1);
  const [numCashRegisters, setNumCashRegisters] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [defaultChecked, setDefaultChecked] = useState(false);
  const [pervichniyUchet, setPervichniyUchet] = useState(false);
  const [pervichniyUchetQuantity, setPervichniyUchetQuantity] = useState(0);
  const [checkSellerDocs, setCheckSellerDocs] = useState(false);
  const [checkSellerDocsCoef, setCheckSellerDocsCoef] = useState(1);
  const [setSellerDocs, setSetSellerDocs] = useState(false);
  const [setSellerDocsCoef, setSetSellerDocsCoef] = useState(1);
  const [checkCustomerDoc, setCheckCustomerDocs] = useState(false);
  const [checkCustomerDocsCoef, setCheckCustomerDocsCoef] = useState(1);
  const [setCustomerDocs, setSetCustomerDocs] = useState(false);
  const [setCustomerDocsCoef, setSetCustomerDocsCoef] = useState(1);
  const [ppQuantity, setPpQuantity] = useState(0);
  const [ppQuantityChecked, setPpQuantityChecked] = useState(false);
  const [zpCalc, setZpCalc] = useState(false);
  const [numEmployees, setNumEmployees] = useState(0);
  const [numEmployeesCoef, setNumEmployeesCoef] = useState(1);
  const [militaryService, setMilitaryService] = useState(0);
  const [kadrUchetGroup, setKadrUchetGroup] = useState(false);
  const [migrationServiceEmployeesCoef, setMigrationServiceEmployeesCoef] = useState(1);
  const [reportingGroup, setReportingGroup] = useState(false);
  const [tovarReport, setTovarReport] = useState(false);
  const [syncBasesQuantity, setSyncBasesQuantity] = useState(0);
  const [cashMachines, setCashMachines] = useState(false);
  const [totalDifficult, setTotalDifficult] = useState(1);
  const [tovarDifficult, setTovarDifficult] = useState(1);
  const [ppCoef, setPPCoef] = useState(1);
  const [pervichniyUchetCostState, setPervichniyUchetCostState] = useState(0);

  useEffect(() => {
    let pervichniyUchetCost = 0;

    if (pervichniyUchet) {
      let operationsCost = pervichniyUchetQuantity * 25;
      operationsCost = !checkSellerDocs
        ? operationsCost * 0.925
        : operationsCost * checkSellerDocsCoef;
      operationsCost = !setSellerDocs ? operationsCost * 0.925 : operationsCost * setSellerDocsCoef;
      operationsCost = !checkCustomerDoc
        ? operationsCost * 0.925
        : operationsCost * checkCustomerDocsCoef;
      operationsCost = !setCustomerDocs
        ? operationsCost * 0.925
        : operationsCost * setCustomerDocsCoef;
      let ppCost =
        (!ppQuantityChecked ? ppQuantity * 100 : pervichniyUchetQuantity * 25 * 0.1) * ppCoef;
      pervichniyUchetCost = operationsCost + ppCost;
    }
    setPervichniyUchetCostState(pervichniyUchetCost);
  }, [
    pervichniyUchet,
    pervichniyUchetQuantity,
    checkSellerDocs,
    checkSellerDocsCoef,
    setSellerDocs,
    setSellerDocsCoef,
    checkCustomerDoc,
    checkCustomerDocsCoef,
    setCustomerDocs,
    setCustomerDocsCoef,
    ppQuantityChecked,
    ppQuantity,
    ppCoef,
  ]);

  const [zpCostState, setZpCostState] = useState(0);

  useEffect(() => {
    let salaryCost = 0;

    if (zpCalc) {
      salaryCost = numEmployees * 600 * numEmployeesCoef;
    }

    setZpCostState(salaryCost);
  }, [numEmployees, numEmployeesCoef, zpCalc]);

  const [reportsState, setReporsState] = useState(0);

  useEffect(() => {
    let reportsForClient = 0;

    if (reportingGroup) {
      let reportCost = reporting ? 5000 * reportingComplexity : 0;
      let tovarReportCost = tovarReport ? 10000 * tovarDifficult : 0;
      let ddsCost = ddsReport ? 3000 * ddsComplexity : 0;
      let paymentCalendarCost = paymentCalendar ? 11000 * paymentCalendarComplexity : 0;
      reportsForClient = reportCost + tovarReportCost + ddsCost + paymentCalendarCost;
    }

    setReporsState(reportsForClient);
  }, [
    ddsComplexity,
    ddsReport,
    paymentCalendar,
    paymentCalendarComplexity,
    reporting,
    reportingComplexity,
    reportingGroup,
    tovarDifficult,
    tovarReport,
  ]);

  const [kadrovyUchetState, setKadrpvyUchetState] = useState(0);

  useEffect(() => {
    let kadrovyUchet = 0;

    if (kadrUchetGroup) {
      const employeesCost = employees * 400 * employeeComplexity;
      const militaryRegistrationCost = militaryRegistration
        ? 3500 * militaryRegistrationComplexity
        : 0;
      const militaryServiceCost = militaryService * 1500 * militaryServiceComplexity;
      let migrationUchetPost = migrationRegistration ? 5000 * migrationRegistrationComplexity : 0;
      let migrationUchetService = migrationServiceEmployees * 1500 * migrationServiceEmployeesCoef;
      kadrovyUchet =
        employeesCost +
        militaryRegistrationCost +
        militaryServiceCost +
        migrationUchetPost +
        migrationUchetService;
    }
    setKadrpvyUchetState(kadrovyUchet);
  }, [
    employeeComplexity,
    employees,
    kadrUchetGroup,
    migrationRegistration,
    migrationRegistrationComplexity,
    migrationServiceEmployees,
    migrationServiceEmployeesCoef,
    militaryRegistration,
    militaryRegistrationComplexity,
    militaryService,
    militaryServiceComplexity,
  ]);

  const [syncState, setSyncState] = useState(0);

  useEffect(() => {
    let syncCost = 0;

    if (syncBases) {
      syncCost = syncBasesQuantity * 5000 * syncBasesComplexity;
    }

    setSyncState(syncCost);
  }, [syncBases, syncBasesComplexity, syncBasesQuantity]);

  const [cashMachinesState, setCashMachinesState] = useState(0);

  useEffect(() => {
    let cashMachinesCost = 0;

    if (cashMachines) {
      cashMachinesCost = numCashRegisters * 3500;
    }

    setCashMachinesState(cashMachinesCost);
  }, [cashMachines, numCashRegisters]);

  const calculateTariff = () => {
    let opfValue = defaultChecked ? 0 : opf === 'ИП' ? 100000 : opf === 'ООО' ? 115000 : 130000;
    let snoCoefficient =
      sno === 'УСН 6%' ? 1 : sno === 'УСН Д-Р' ? 1.2 : sno === 'ОСНО' ? 1.3 : 1.4;

    let opCoefficient = 0;

    if (+op > 0 && !defaultChecked) {
      opCoefficient = opfValue * snoCoefficient * 0.1;
      opCoefficient = opCoefficient + (+op - 1) * 5000;
    }

    let vedCoefficient = ved ? 1.3 : 1;
    let not1CBPCoefficient = not1CBP ? 1.25 : 1;
    let workInBPCoefficient = workInBP ? 1.1 : 1;
    let not1CZUPCoefficient = not1CZUP ? 1.25 : 1;
    let workInZUPCoefficient = workInZUP ? 1.1 : 1;

    let baseTariff =
      (opfValue * snoCoefficient + opCoefficient + reportsState + pervichniyUchetCostState) *
        vedCoefficient *
        not1CBPCoefficient *
        workInBPCoefficient +
      (zpCostState + kadrovyUchetState) * not1CZUPCoefficient * workInZUPCoefficient +
      cashMachinesState +
      syncState;

    return baseTariff * totalDifficult;
  };

  const [tab, setTab] = useState(1);
  const [visited, setVisited] = useState([]);

  const nextStep = () => {
    setVisited((prev) => {
      return Array.from(new Set([...prev, tab]));
    });
    setTab((prev) => prev + 1);
  };

  const prevStep = () => {
    setTab((prev) => prev - 1);
  };

  const [tabsH, setTabsH] = useState(0);
  const tabsRef = useRef(null);

  useEffect(() => {
    if (!tabsRef.current) {
      return;
    }
    const observer = new ResizeObserver(() => {
      setTabsH(tabsRef.current.offsetTop + tabsRef.current.offsetHeight);
    });
    observer.observe(tabsRef.current);
    return () => observer.disconnect();
  }, [tabsRef]);

  useEffect(() => {
    if (!checkSellerDocs) setSetSellerDocs(false);
    if (!checkCustomerDoc) setSetCustomerDocs(false);
  }, [checkCustomerDoc, checkSellerDocs]);

  const checkVisited = (id) => {
    switch (id) {
      case 2:
        return !pervichniyUchet || !!pervichniyUchetCostState;
      case 3:
        return !zpCalc || !!pervichniyUchetCostState;
      case 4:
        return !kadrUchetGroup || !!kadrovyUchetState;
      case 5:
        return !reportingGroup || !!reportsState;
      case 6:
        return !syncBases || !!syncState;
      case 7:
        return !cashMachines || !!cashMachinesState;
      default:
        return true;
    }
  };

  const text = (() => {
    const price = calculateTariff();
    const discountedPrice = calculateDiscountedPrice(price, discount);
    let start = `Коммерческое предложение для ____ \r\n\r\nТариф стоимостью — ${new Intl.NumberFormat(
      'ru-Ru',
      { maximumFractionDigits: 2, minimumFractionDigits: 2 },
    ).format(price)} рублей в месяц.\r\n`;

    if (+discount)
      start += `С учетом скидки стоимость составит ${new Intl.NumberFormat('ru-Ru', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(discountedPrice)} рублей в месяц.\r\n`;

    start += `\r\nПараметры тарифа:\r\n\r\n`;

    if (!defaultChecked) {
      start += `ОПФ - ${opf}\r\nСНО - ${sno}\r\nОП - ${op ? op + ' (шт.)' : 'Нет'}\r\n`;
    }

    start += `ВЭД - ${ved ? 'Да' : 'Нет'}\r\n\r\n`;

    start += `Учет в:\r\n\r\n`;

    start += `${not1CZUP ? 'Не 1С ЗУП\r\n' : '1С ЗУП'}\r\n`;
    start += `${not1CBP ? 'Не 1С БП\r\n' : '1С БП'}\r\n`;
    start += (workInBP ? 'Учет в базе клиента БП' : 'Учет не в базе клиента БП') + '\r\n';
    start += `${workInZUP ? 'Учет в базе клиента ЗУП' : 'Учет не в базе клиента ЗУП'}`;

    if (pervichniyUchet) {
      start += `\r\nПервичный учет:\r\n\r\n`;
      start += `Кол-во операций - ${pervichniyUchetQuantity} шт.\r\n`;
      if (checkSellerDocs) start += 'Проверка документов от поставщика\r\n';
      if (setSellerDocs) start += 'Заведение документов от поставщика\r\n';
      if (checkCustomerDoc) start += 'Проверка документов от покупателей\r\n';
      if (setCustomerDocs) start += 'Заведение документов от покупателей\r\n';
      if (ppQuantity > 0 && !ppQuantityChecked)
        start += `Кол-во платёжный поручений - ${ppQuantity} шт.\r\n`;
    }

    if (zpCalc) {
      start += `\r\nРасчет заработной платы:\r\n\r\n`;
      start += `Кол-во сотрудников - ${numEmployees} чел.\r\n\r\n`;
    }

    if (kadrUchetGroup) {
      start += `\r\nКадровое делопроизводство:\r\n\r\n`;
      if (employees) start += `Кадровый учет, кол-во сотрудников - ${employees} чел.\r\n`;
      if (militaryRegistration) start += 'Воинский учет (постановка)\r\n';
      if (militaryService) start += `Воинский учет (обслуживание) - ${militaryService} чел.\r\n`;
      if (migrationRegistration) start += 'Миграционный учет (постановка)\r\n';
      if (migrationServiceEmployees)
        start += `Миграционный учет (обслуживание) - ${migrationServiceEmployees} чел.\r\n`;
    }

    if (reportingGroup) {
      start += `\r\nОтчетность для клиента:\r\n\r\n`;
      if (reporting) start += 'Отчет о реализации\r\n';
      if (tovarReport) start += 'Отчет о товарных остатках\r\n';
      if (ddsReport) start += 'Отчет о ДДС\r\n';
      if (paymentCalendar) start += 'Формирование платежного календаря и утверждение клиентом\r\n';
    }

    if (syncBases) {
      start += `\r\nСинхронизация баз:\r\n\r\n`;
      start += `Кол-во синхронизаций - ${+syncBasesQuantity} шт.`;
    }

    if (cashMachines) {
      start += `\r\nККМ:\r\n\r\n`;
      start += `Кол-во контрольно кассовых машин - ${+numCashRegisters} шт.`;
    }
    return start;
  })();

  return (
    <Box className="App" sx={{ px: 8, py: 4, height: '100dvh' }}>
      <Stack maxWidth="1312px" mx="auto">
        <Stack flexDirection="row" justifyContent="space-between" mb={'36px'} flexWrap="wrap">
          <Logo />
          <PhrasesBox phrases={phrases} />
        </Stack>
        <Typography
          mb={3}
          variant="h1"
          sx={{ fontSize: '32px', lineHeight: '36px', fontWeight: 700 }}
        >
          Расчет тарифов для среднего бизнеса
        </Typography>
        <Stack flexDirection="row" gap={2}>
          <Box flex={1} sx={{ pr: 2, borderRight: '1px dashed #DDDEE7' }}>
            <Tabs
              ref={tabsRef}
              sx={{
                '.MuiTabs-flexContainer': { gap: 1, flexWrap: 'wrap' },
                '.MuiTabs-indicator': { display: 'none' },
                flexWrap: 'wrap',
                display: 'flex',
              }}
              value={+tab}
              onChange={(_, v) => {
                setVisited((prev) => Array.from(new Set([...prev, tab])));
                setTab(+v);
              }}
            >
              {Object.entries(steps).map(([key, name]) => (
                <Tab
                  sx={{
                    backgroundColor: !visited.includes(+key)
                      ? '#fff'
                      : checkVisited(+key)
                      ? '#2DC970'
                      : '#DA0E17',
                    borderRadius: '46px',
                    color: !visited.includes(+key) ? '#515568' : 'white',
                    '&.Mui-selected': { color: 'white', background: '#515568' },
                  }}
                  label={
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="nowrap"
                      alignItems="center"
                      justifyContent="center"
                      gap={0.5}
                      textTransform="none"
                      sx={{ paddingX: '18px', pl: visited.includes(+key) ? '31px' : '18px' }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          left: '12px',
                          width: '16px',
                        }}
                      >
                        {!visited.includes(+key) ? (
                          <></>
                        ) : checkVisited(+key) ? (
                          <Check />
                        ) : (
                          <ErrorOutline />
                        )}
                      </Box>
                      {name}
                    </Box>
                  }
                  key={name}
                  value={+key}
                />
              ))}
            </Tabs>
            <Box
              sx={{
                mt: '26px',
                pt: 1,
                pb: 1,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxHeight: `calc(100dvh - ${tabsH}px - 30px)`,
                overflowY: 'auto',
                alignItems: 'start',
              }}
            >
              {tab === 1 && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={defaultChecked}
                          onChange={(e) => {
                            setDefaultChecked(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={3}
                          alignSelf="flex-start"
                        >
                          Вести расчет без СНО, ОПФ и ОП
                        </Box>
                      }
                    ></FormControlLabel>
                  </FormGroup>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: 2,
                    }}
                  >
                    <TextField
                      select
                      SelectProps={{ IconComponent: KeyboardArrowDown }}
                      sx={{ svg: { color: '#0278FF' } }}
                      value={opf}
                      onChange={(e) => setOpf(e.target.value)}
                      label={
                        <Box>
                          Организационно правовая форма <span style={{ color: ' #DA0E17' }}>*</span>
                        </Box>
                      }
                    >
                      <MenuItem value="ИП">ИП</MenuItem>
                      <MenuItem value="ООО">ООО</MenuItem>
                      <MenuItem value="АО">АО</MenuItem>
                    </TextField>
                    <TextField
                      label={
                        <Box>
                          Система налогообложения <span style={{ color: ' #DA0E17' }}>*</span>
                        </Box>
                      }
                      select
                      sx={{ svg: { color: '#0278FF' } }}
                      SelectProps={{ IconComponent: KeyboardArrowDown }}
                      value={sno}
                      onChange={(e) => setSno(e.target.value)}
                    >
                      <MenuItem value="УСН 6%">УСН 6%</MenuItem>
                      <MenuItem value="УСН Д-Р">УСН Д-Р</MenuItem>
                      <MenuItem value="ОСНО">ОСНО</MenuItem>
                      <MenuItem value="Совмещение">
                        совмещение (когда есть несколько видов режимов налогообложения){' '}
                      </MenuItem>
                    </TextField>
                    <TextField
                      type="number"
                      value={op}
                      onChange={(e) =>
                        setOp(
                          +e.target.value > 100 ? 100 : +e.target.value < 0 ? 0 : e.target.value,
                        )
                      }
                      min="0"
                      max="100"
                      label="Количество ОП"
                    />
                  </Box>

                  <FormGroup sx={{ height: '56px' }} width="100%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          type="checkbox"
                          checked={ved}
                          onChange={(e) => setVed(e.target.checked)}
                        />
                      }
                      label="ВЭД"
                    />
                  </FormGroup>

                  <FormGroup sx={{ height: '56px' }} width="100%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          type="checkbox"
                          checked={not1CBP}
                          onChange={(e) => setNot1CBP(e.target.checked)}
                        />
                      }
                      label="Учет не в 1С БП"
                    />
                  </FormGroup>

                  <FormGroup sx={{ height: '56px' }} width="100%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          type="checkbox"
                          checked={not1CZUP}
                          onChange={(e) => setNot1CZUP(e.target.checked)}
                        />
                      }
                      label="Учет не в 1С ЗУП"
                    />
                  </FormGroup>

                  <FormGroup sx={{ height: '56px' }} width="100%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          type="checkbox"
                          checked={workInBP}
                          onChange={(e) => setWorkInBP(e.target.checked)}
                        />
                      }
                      label="Учет в базе клиента БП"
                    />
                  </FormGroup>

                  <FormGroup sx={{ height: '56px' }} width="100%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          type="checkbox"
                          checked={workInZUP}
                          onChange={(e) => setWorkInZUP(e.target.checked)}
                        />
                      }
                      label="Учет в базе клиента ЗУП"
                    />
                  </FormGroup>

                  <Box sx={{ color: 'rgba(159, 164, 187, 1)' }}>
                    <span style={{ color: ' #DA0E17' }}>*</span> - обязательные поля
                  </Box>
                </>
              )}

              {tab === 2 && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pervichniyUchet}
                          onChange={(e) => {
                            setPervichniyUchet(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={3}
                          alignSelf="flex-start"
                        >
                          Первичный учет{' '}
                          <Pop
                            width="314px"
                            suggestion="Выберите чек-бокс, если клиент хочет воспользоваться услугами из раздела"
                          />
                        </Box>
                      }
                    ></FormControlLabel>
                  </FormGroup>
                  <Box
                    width="100%"
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: 2,
                    }}
                  >
                    <TextField
                      width="100%"
                      label="Кол-во операций"
                      type="number"
                      disabled={!pervichniyUchet}
                      value={pervichniyUchetQuantity}
                      onChange={(e) =>
                        setPervichniyUchetQuantity(
                          +e.target.value > 10_000
                            ? 10_000
                            : +e.target.value < 0
                            ? 0
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: 2,
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!pervichniyUchet}
                        control={
                          <Checkbox
                            disabled={!pervichniyUchet}
                            checked={checkSellerDocs}
                            onChange={(e) => {
                              setCheckSellerDocs(e.target.checked);
                            }}
                          />
                        }
                        label="Проверка документов от поставщика"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      value={checkSellerDocsCoef}
                      onChange={(e) => {
                        setCheckSellerDocsCoef(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        );
                      }}
                      label="Коэффициент сложности"
                      disabled={!pervichniyUchet}
                      type="number"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!pervichniyUchet || !checkSellerDocs}
                        control={
                          <Checkbox
                            disabled={!pervichniyUchet || !checkSellerDocs}
                            checked={setSellerDocs}
                            onChange={(e) => {
                              setSetSellerDocs(e.target.checked);
                            }}
                          />
                        }
                        label="Заведение документов от поставщика"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      disabled={!pervichniyUchet}
                      value={setSellerDocsCoef}
                      onChange={(e) =>
                        setSetSellerDocsCoef(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      label="Коэффициент сложности"
                      type="number"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!pervichniyUchet}
                        control={
                          <Checkbox
                            disabled={!pervichniyUchet}
                            checked={checkCustomerDoc}
                            onChange={(e) => {
                              setCheckCustomerDocs(e.target.checked);
                            }}
                          />
                        }
                        label="Проверка документов от покупателей"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      disabled={!pervichniyUchet}
                      value={checkCustomerDocsCoef}
                      onChange={(e) =>
                        setCheckCustomerDocsCoef(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      label="Коэффициент сложности"
                      type="number"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!pervichniyUchet || !checkCustomerDoc}
                        control={
                          <Checkbox
                            checked={setCustomerDocs}
                            disabled={!pervichniyUchet || !checkCustomerDoc}
                            onChange={(e) => {
                              setSetCustomerDocs(e.target.checked);
                            }}
                          />
                        }
                        label="Заведение документов от покупателей"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      value={setCustomerDocsCoef}
                      onChange={(e) =>
                        setSetCustomerDocsCoef(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      label="Коэффициент сложности"
                      disabled={!pervichniyUchet}
                      type="number"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{
                      fontSize: '20px',
                      lineHeight: '36px',
                      fontWeight: 700,
                      minHeight: '36px',
                    }}
                  >
                    Формирование платежных поручений:
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <TextField
                      label="Кол-во платежных поручений"
                      value={ppQuantity}
                      disabled={!pervichniyUchet}
                      onChange={(e) =>
                        setPpQuantity(
                          e.target.value < 0 ? 0 : e.target.value > 1000 ? 1000 : e.target.value,
                        )
                      }
                      type="number"
                      min="1"
                      max="1000"
                    />
                    <TextField
                      label="Коэффициент сложности"
                      value={ppCoef}
                      disabled={!pervichniyUchet}
                      type="number"
                      onChange={(e) =>
                        setPPCoef(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                    />
                  </Box>
                  <Box display={'grid'} gridTemplateColumns="repeat(2, 1fr)" gap={2}>
                    <Stack flexDirection="row" alignItems="center">
                      <Box
                        sx={{
                          fontSize: '14px',
                          fontWeight: 300,
                          lineHeight: '22px',
                          mr: 2,
                        }}
                      >
                        или
                      </Box>
                      <FormGroup>
                        <FormControlLabel
                          disabled={!pervichniyUchet}
                          control={
                            <Checkbox
                              disabled={!pervichniyUchet}
                              checked={ppQuantityChecked}
                              onChange={(e) => {
                                setPpQuantityChecked(e.target.checked);
                              }}
                            />
                          }
                          label="10% от кол-ва операций"
                        ></FormControlLabel>
                      </FormGroup>
                    </Stack>
                  </Box>
                </>
              )}

              {tab === 3 && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={zpCalc}
                          onChange={(e) => {
                            setZpCalc(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={3}
                          sx={{ width: '100%' }}
                        >
                          Расчет заработной платы{' '}
                          <Pop
                            width="314px"
                            suggestion="Выберите чек-бокс, если клиент хочет воспользоваться услугами из раздела"
                          />
                        </Box>
                      }
                    ></FormControlLabel>
                  </FormGroup>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <TextField
                      label="Кол-во сотрудников"
                      type="number"
                      disabled={!zpCalc}
                      value={numEmployees}
                      onChange={(e) =>
                        setNumEmployees(
                          !e.target.value
                            ? e.target.value
                            : e.target.value > 0
                            ? e.target.value
                            : 0,
                        )
                      }
                      min="0"
                      max="100"
                    />
                    <TextField
                      value={numEmployeesCoef}
                      onChange={(e) =>
                        setNumEmployeesCoef(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      label="Коэффициент сложности"
                      disabled={!zpCalc}
                      type="number"
                    />
                  </Box>
                </>
              )}

              {tab === 4 && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kadrUchetGroup}
                          onChange={(e) => {
                            setKadrUchetGroup(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={3}
                          sx={{ width: '100%' }}
                        >
                          Кадровое делопроизводство{' '}
                          <Pop
                            width="314px"
                            suggestion="Выберите чек-бокс, если клиент хочет воспользоваться услугами из раздела"
                          />
                        </Box>
                      }
                    ></FormControlLabel>
                  </FormGroup>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <TextField
                      type="number"
                      disabled={!kadrUchetGroup}
                      label="Кадровый учет, кол-во сотрудников"
                      value={employees}
                      onChange={(e) =>
                        setemployees(
                          +e.target.value > 100 ? 100 : +e.target.value < 0 ? 0 : e.target.value,
                        )
                      }
                      min="0"
                      max="100"
                    />
                    <TextField
                      label="Коэффициент сложности"
                      disabled={!kadrUchetGroup}
                      type="number"
                      value={employeeComplexity}
                      onChange={(e) =>
                        setEmployeeComplexity(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      min="1"
                      max="100"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!kadrUchetGroup}
                        control={
                          <Checkbox
                            disabled={!kadrUchetGroup}
                            checked={militaryRegistration}
                            onChange={(e) => setMilitaryRegistration(e.target.checked)}
                          />
                        }
                        label="Воинский учет (постановка)"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      value={militaryRegistrationComplexity}
                      onChange={(e) =>
                        setMilitaryRegistrationComplexity(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      label="Коэффициент сложности"
                      type="number"
                      disabled={!kadrUchetGroup}
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <TextField
                      disabled={!kadrUchetGroup}
                      value={militaryService}
                      onChange={(e) => {
                        setMilitaryService(
                          +e.target.value > 100 ? 100 : +e.target.value >= 0 ? e.target.value : 0,
                        );
                      }}
                      label="Воинский учет (обслуживание)"
                    />
                    <TextField
                      value={militaryServiceComplexity}
                      onChange={(e) =>
                        setMilitaryServiceComplexity(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      label="Коэффициент сложности"
                      type="number"
                      disabled={!kadrUchetGroup}
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!kadrUchetGroup}
                        control={
                          <Checkbox
                            disabled={!kadrUchetGroup}
                            checked={migrationRegistration}
                            onChange={(e) => setMigrationRegistration(e.target.checked)}
                          />
                        }
                        label="Миграционный учет (постановка)"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      value={migrationRegistrationComplexity}
                      onChange={(e) =>
                        setMigrationRegistrationComplexity(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      label="Коэффициент сложности"
                      type="number"
                      disabled={!kadrUchetGroup}
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <TextField
                      disabled={!kadrUchetGroup}
                      value={migrationServiceEmployees}
                      onChange={(e) =>
                        setMigrationServiceEmployees(
                          +e.target.value > 100 ? 100 : +e.target.value >= 0 ? e.target.value : 0,
                        )
                      }
                      label="Миграционный учет (обслуживание)"
                    />
                    <TextField
                      disabled={!kadrUchetGroup}
                      value={migrationServiceEmployeesCoef}
                      onChange={(e) =>
                        setMigrationServiceEmployeesCoef(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      label="Коэффициент сложности"
                      type="number"
                    />
                  </Box>
                </>
              )}

              {tab === 5 && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reportingGroup}
                          onChange={(e) => {
                            setReportingGroup(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap={3}
                          sx={{ width: '100%' }}
                        >
                          Отчетность для клиента{' '}
                          <Pop
                            width="314px"
                            suggestion="Выберите чек-бокс, если клиент хочет воспользоваться услугами из раздела"
                          />
                        </Box>
                      }
                    ></FormControlLabel>
                  </FormGroup>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!reportingGroup}
                        control={
                          <Checkbox
                            checked={reporting}
                            onChange={(e) => setReporting(e.target.checked)}
                          />
                        }
                        label="Отчет о реализации"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      value={reportingComplexity}
                      disabled={!reportingGroup}
                      onChange={(e) =>
                        setReportingComplexity(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      min="1"
                      max="100"
                      label="Коэффициент сложности"
                      type="number"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!reportingGroup}
                        control={
                          <Checkbox
                            disabled={!reportingGroup}
                            checked={tovarReport}
                            onChange={(e) => setTovarReport(e.target.checked)}
                          />
                        }
                        label="Отчет о товарных остатках"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      disabled={!reportingGroup}
                      value={tovarDifficult}
                      onChange={(e) =>
                        setTovarDifficult(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      min="1"
                      max="100"
                      label="Коэффициент сложности"
                      type="number"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!reportingGroup}
                        control={
                          <Checkbox
                            disabled={!reportingGroup}
                            checked={ddsReport}
                            onChange={(e) => setDdsReport(e.target.checked)}
                          />
                        }
                        label="Отчет о ДДС"
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      value={ddsComplexity}
                      disabled={!reportingGroup}
                      onChange={(e) =>
                        setDdsComplexity(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      min="1"
                      max="100"
                      label="Коэффициент сложности"
                      width="100%"
                      type="number"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled={!reportingGroup}
                        control={
                          <Checkbox
                            checked={paymentCalendar}
                            onChange={(e) => setPaymentCalendar(e.target.checked)}
                          />
                        }
                        label={<>Формирование платежного календаря{<br />}и утверждение клиентом</>}
                      ></FormControlLabel>
                    </FormGroup>
                    <TextField
                      disabled={!reportingGroup}
                      value={paymentCalendarComplexity}
                      onChange={(e) =>
                        setPaymentCalendarComplexity(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      min="1"
                      max="100"
                      label="Коэффициент сложности"
                      width="100%"
                      type="number"
                    />
                  </Box>
                </>
              )}

              {tab === 6 && (
                <>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={syncBases}
                            onChange={(e) => setSyncBases(e.target.checked)}
                          />
                        }
                        label={
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={3}
                            sx={{ width: '100%' }}
                          >
                            Синхронизация баз{' '}
                            <Pop
                              width="314px"
                              suggestion="Выберите чек-бокс, если клиент хочет воспользоваться услугами из раздела"
                            />
                          </Box>
                        }
                      ></FormControlLabel>
                    </FormGroup>
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <TextField
                      width="100%"
                      disabled={!syncBases}
                      type="number"
                      label="Коэффициент сложности"
                      value={syncBasesComplexity}
                      onChange={(e) =>
                        setSyncBasesComplexity(
                          +e.target.value > 100
                            ? 100
                            : !!e.target.value && +e.target.value < 1
                            ? 1
                            : e.target.value.replace(/\0-9/g, ''),
                        )
                      }
                      min="1"
                      max="100"
                    />
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <TextField
                      width="100%"
                      disabled={!syncBases}
                      type="number"
                      label="Кол-во синхронизаций"
                      value={syncBasesQuantity}
                      onChange={(e) =>
                        setSyncBasesQuantity(
                          +e.target.value < 0 ? 0 : +e.target.value > 100 ? 100 : e.target.value,
                        )
                      }
                      min="1"
                      max="100"
                    />
                  </Box>
                </>
              )}

              {tab === 7 && (
                <>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cashMachines}
                            onChange={(e) => setCashMachines(e.target.checked)}
                          />
                        }
                        label={
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ width: '100%' }}
                            gap={3}
                          >
                            ККМ{' '}
                            <Pop
                              width="314px"
                              suggestion="Выберите чек-бокс, если клиент хочет воспользоваться услугами из раздела"
                            />
                          </Box>
                        }
                      ></FormControlLabel>
                    </FormGroup>
                  </Box>
                  <Box
                    width="100%"
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
                  >
                    <TextField
                      type="number"
                      disabled={!cashMachines}
                      label="Кол-во контрольно кассовых машин"
                      value={numCashRegisters}
                      onChange={(e) =>
                        setNumCashRegisters(
                          +e.target.value < 0 ? 0 : +e.target.value > 100 ? 100 : e.target.value,
                        )
                      }
                      min="1"
                    />
                  </Box>
                </>
              )}
              <Stack flexDirection="row" width="100%" justifyContent="space-between" mt={'25px'}>
                <Button
                  variant="contained"
                  onClick={prevStep}
                  disabled={tab === 1}
                  color="inherit"
                  sx={{
                    backgroundColor: '#fff',
                    height: '54px',
                    width: '91px',
                    fontSize: '16px',
                    lineHeight: '22px',
                    textTransform: 'none',
                    opacity: Number(tab !== 1),
                    borderRadius: '8px',
                    color: '#515568',
                  }}
                >
                  Назад
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#DA0E17',
                    height: '54px',
                    width: '93px',
                    fontSize: '16px',
                    lineHeight: '22px',
                    textTransform: 'none',
                    borderRadius: '8px',
                    opacity: Number(tab !== 7),
                  }}
                  variant="contained"
                  color="error"
                  onClick={nextStep}
                  disabled={tab === 7}
                >
                  Далее
                </Button>
              </Stack>
            </Box>
          </Box>
          <Stack
            flexDirection="column"
            width="340px"
            sx={{
              gap: '26px',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 169px)',
              mt: -0.5,
              pt: 0.5,
            }}
          >
            <CalcSelect />
            <TextField
              value={calculateDiscountedPrice(calculateTariff(), discount).toFixed(2)}
              label="Общая стоимость"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CurrencyRuble sx={{ color: '#9FA4BB' }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              select
              value={totalDifficult}
              sx={{ svg: { color: '#0278FF' } }}
              SelectProps={{ IconComponent: KeyboardArrowDown }}
              onChange={(e) => {
                setTotalDifficult(e.target.value);
              }}
              label="Общий коэф-т сложности"
            >
              {Array(7)
                .fill(null)
                .map((_, i) => (
                  <MenuItem key={'select-dif' + i} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              label="Общая скидка"
              value={discount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Percent sx={{ color: '#9FA4BB' }} />
                  </InputAdornment>
                ),
              }}
              type="number"
              onChange={(e) => {
                setDiscount(+e.target.value < 0 ? 0 : +e.target.value > 100 ? 100 : e.target.value);
              }}
            />
            <TextField
              label={<Box sx={{ backgroundColor: '#F2F3F8', px: 0.5 }}>Описание для клиента:</Box>}
              multiline
              sx={{ flex: 1, display: 'flex' }}
              value={text}
              InputProps={{
                sx: { flex: 1, alignItems: 'start', fontSize: '10pt' },
              }}
            />
            <Button
              startIcon={<CopyAllOutlined />}
              sx={{ textTransform: 'none', mb: 2 }}
              variant="outlined"
              onClick={() => copyToBuffer(text)}
            >
              Копировать
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TariffCalculator;
