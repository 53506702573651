import {
  Box,
  Checkbox,
  TextField,
  Stack,
  Tab,
  Tabs,
  Typography,
  MenuItem,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Link,
  Button,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Logo } from '../logo';
import { PhrasesBox } from '../Phrases';
import {
  CopyAllOutlined,
  CurrencyRuble,
  KeyboardArrowDown,
  Percent,
  Search,
} from '@mui/icons-material';
import servicesData from './data';
import { calculateDiscountedPrice, copyToBuffer, numberToWords } from '../../helpers';
import { Pop } from './pop';
import { CalcSelect } from '../calc-select';

const flatList = Object.values(servicesData).flat();

const nameMap = new Map(flatList.map((item) => [item.id, item.full]));

const arr = Object.keys(servicesData);

function calculatePriceBySteps(documentCount, perStep) {
  const step = 20; // Количество документов для шага
  const pricePerStep = perStep; // Стоимость за шаг

  // Округляем количество документов в большую сторону до ближайшего шага
  const steps = Math.ceil(documentCount / step);

  // Рассчитываем стоимость
  const totalPrice = steps * pricePerStep;

  return totalPrice;
}
function calculatePriceForFL(flCount) {
  if (flCount >= 1 && flCount <= 2) {
    return 1000;
  } else if (flCount >= 3 && flCount <= 5) {
    return 2000;
  } else if (flCount >= 6 && flCount <= 10) {
    return 4000;
  } else if (flCount >= 11 && flCount <= 20) {
    return 7000;
  } else if (flCount >= 21 && flCount <= 30) {
    return 12000;
  } else {
    const extraFl = Math.ceil((flCount - 30) / 10);
    return 12000 + extraFl * 5000;
  }
}

function calculatePriceForEmployees(employeeCount) {
  if (employeeCount <= 5) {
    return 900;
  } else if (employeeCount <= 10) {
    return 1300;
  } else if (employeeCount <= 15) {
    return 1500;
  } else if (employeeCount <= 20) {
    return 2000;
  } else {
    // Для количества сотрудников больше 20, шаг в 5 сотрудников добавляет по 500 ₽
    const extraEmployees = Math.ceil((employeeCount - 20) / 5);
    return 2000 + extraEmployees * 500;
  }
}

const calculateItem = (item) => {
  if (item.quantity === 0) return 0;
  if (typeof item.tariff !== 'undefined') {
    return calculateDiscountedPrice(
      ((item.price * item.tariff) / 100) * item.quantity * item.coef,
      item.discount,
    );
  }
  switch (item.id) {
    case 'employee-data-corrections':
      return calculateDiscountedPrice(
        calculatePriceForEmployees(item.quantity) * item.coef,
        item.discount,
      );
    case '1c-module-installation':
      return item.price;
    case 'debt-collection':
      return calculateDiscountedPrice(
        ((item.quantity * item.price) / 100) * item.coef,
        item.discount,
      );
    case 'accounting-for-branch':
      let price = item.price;
      price += (item.quantity - 1) * 2500;
      return calculateDiscountedPrice(price * item.coef, item.discount);

    case 'legal-primary-documents-input':
      return calculateDiscountedPrice(
        calculatePriceBySteps(item.quantity, item.price) * item.coef,
        item.discount,
      );
    case 'report-6-ndfL':
      return calculateDiscountedPrice(
        calculatePriceForFL(item.quantity) * item.coef,
        item.discount,
      );
    default:
      return calculateDiscountedPrice(item.price * item.quantity * item.coef, item.discount);
  }
};

const makeTotal = (selected, totalCoef) => {
  return (
    Object.values(selected).reduce((acc, item) => {
      return acc + calculateItem(item);
    }, 0) * totalCoef
  );
};

const checkTab = (arr, selected) => {
  const check = arr.reduce(
    (acc, service) => {
      if ('divider' in service) return acc;
      if (selected[service.id].quantity > 0) {
        acc.quantity += 1;
        acc.total += service.cost * selected[service.id].coef * selected[service.id].quantity;
      }
      return acc;
    },
    { quantity: 0, total: 0 },
  );
  return check;
};

function Dop({ phrases }) {
  const [tab, setTab] = useState(arr[0]);
  const [selected, setSelected] = useState(
    flatList.reduce((acc, item) => {
      if (typeof item.divider === 'string') {
        return acc;
      }
      if (item.id === '1c-module-installation') {
        acc[item.id] = { price: 0, id: item.id, quantity: 0 };
        return acc;
      }
      acc[item.id] = {
        quantity: 0,
        coef: 1,
        discount: 0,
        price: item.cost,
        id: item.id,
        tariff: item.tariff,
      };
      return acc;
    }, {}),
  );
  const [filter, setFiler] = useState('');
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalDifficult, setTotalDifficult] = useState(1);

  const hasFilter = !!filter;

  const list = useMemo(() => (hasFilter ? flatList : servicesData[tab]), [hasFilter, tab]);

  const res = useMemo(
    () => calculateDiscountedPrice(makeTotal(selected, totalDifficult), totalDiscount),
    [selected, totalDifficult, totalDiscount],
  );

  const text = useMemo(() => {
    return `Перечень услуг на общую сумму ${res.toFixed(2)} (${numberToWords(res)} руб. ${
      res.toFixed(2).toString().split('.')[1]
    } коп)\r\n${Object.entries(selected)
      .filter(([, item]) => !!item.quantity)
      .reduce((acc, [key, value], i, arr) => {
        const total = calculateItem(value);
        return (
          acc +
          (i + 1) +
          '. ' +
          nameMap.get(key) +
          ' - ' +
          calculateDiscountedPrice(total * totalDifficult, totalDiscount).toFixed(2) +
          `${i < arr.length - 1 ? ';' : '.'}` +
          '\r\n'
        );
      }, '')}`;
  }, [res, selected, totalDifficult, totalDiscount]);

  return (
    <Box className="App" sx={{ px: 8, py: 4, height: '100dvh' }}>
      <Stack maxWidth="1312px" mx="auto">
        <Stack flexDirection="row" justifyContent="space-between" mb={'36px'} flexWrap="wrap">
          <Logo />
          <PhrasesBox phrases={phrases} />
        </Stack>
        <Typography
          mb={3}
          variant="h1"
          sx={{ fontSize: '32px', lineHeight: '36px', fontWeight: 700 }}
        >
          Дополнительные услуги
        </Typography>

        <Stack flexDirection="row" gap={2} alignItems="start">
          <Box flex={1}>
            <TextField
              fullWidth
              placeholder="Поиск"
              sx={{ mb: 2, pr: 2 }}
              value={filter}
              onChange={(e) => setFiler(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search sx={{ color: '#0278FF' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Tabs
              sx={{
                '.MuiTabs-flexContainer': { gap: 1 },
                '.MuiTabs-indicator': { display: 'none' },
                opacity: Number(!hasFilter),
              }}
              value={tab}
              onChange={(_, v) => {
                setTab(v);
              }}
            >
              {arr.map((key) => (
                <Tab
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '46px',
                    color: '#515568',
                    '&.Mui-selected': { color: 'white', background: '#515568' },
                  }}
                  label={
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="nowrap"
                      alignItems="center"
                      justifyContent="center"
                      gap={0.5}
                      textTransform="capitalize"
                      sx={{ paddingX: '18px' }}
                    >
                      {key}
                      {checkTab(servicesData[key], selected).quantity > 0 && (
                        <Box
                          sx={{
                            borderRadius: '50%',
                            backgroundColor: '#DA0E17',
                            padding: 0.5,
                            fontSize: '10px',
                            color: 'white',
                            height: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: '15px',
                          }}
                        >
                          {checkTab(servicesData[key], selected).quantity}
                        </Box>
                      )}
                    </Box>
                  }
                  key={key}
                  value={key}
                />
              ))}
            </Tabs>
            <Box
              display="flex"
              sx={{
                marginTop: '38px',
                border: '1px dashed  #DDDEE7',
                p: 2,
                display: 'flex',
                gap: 2,
                maxHeight: 'calc(100vh - 328px)',
                overflowY: 'auto',
              }}
              gap={2}
              flexDirection="column"
            >
              {list.map((item, i) => {
                if ('divider' in item) {
                  return (
                    <Box
                      sx={{
                        fontSize: '20px',
                        lineHeight: '36px',
                        fontWeight: 700,
                        minHeight: '36px',
                        display: 'flex',
                        flexWrap: 'nowrap',
                        gap: 2,
                      }}
                      key={item.divider + 'divider' + i}
                    >
                      {item.divider}
                      {item.suggestion && <Pop suggestion={item.suggestion} />}
                    </Box>
                  );
                }
                if (item.id === '1c-module-installation') {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: item.full.toUpperCase().includes(filter.toUpperCase())
                          ? 'flex'
                          : 'none',
                      }}
                      gap={2}
                    >
                      <Box width="320px">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selected[item.id].price > 0}
                                onChange={(_, val) => {
                                  setSelected((prev) => {
                                    const newV = {
                                      ...prev,
                                      [item.id]: {
                                        ...prev[item.id],
                                        quantity: Number(val),
                                        price: Number(val),
                                      },
                                    };
                                    return newV;
                                  });
                                }}
                                value={item.id}
                              />
                            }
                            sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {item.name}
                                {item.suggestion && <Pop suggestion={item.suggestion} />}
                              </Box>
                            }
                          ></FormControlLabel>
                        </FormGroup>
                      </Box>
                      <TextField
                        sx={{ width: '131px' }}
                        label="Стоимость модуля"
                        type="number"
                        disabled={selected[item.id].quantity === 0}
                        value={selected[item.id].price}
                        onChange={(e) =>
                          setSelected((prev) => {
                            return {
                              ...prev,
                              [item.id]: {
                                ...prev[item.id],
                                price: Number(e.target.value),
                                quantity: Number(Boolean(Number(e.target.value))),
                              },
                            };
                          })
                        }
                      />
                    </Box>
                  );
                }
                if (item.id === 'debt-collection') {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: item.name.toUpperCase().includes(filter.toUpperCase())
                          ? 'flex'
                          : 'none',
                      }}
                      gap={2}
                    >
                      <Box width="320px">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selected[item.id].quantity > 0}
                                onChange={(_, val) => {
                                  setSelected((prev) => ({
                                    ...prev,
                                    [item.id]: { ...prev[item.id], quantity: Number(val) },
                                  }));
                                }}
                                value={item.id}
                              />
                            }
                            sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {item.name}
                                {item.suggestion && <Pop suggestion={item.suggestion} />}
                              </Box>
                            }
                          ></FormControlLabel>
                        </FormGroup>
                      </Box>

                      <Box flex={1}>
                        <TextField
                          fullWidth
                          value={selected[item.id].quantity}
                          disabled={selected[item.id].quantity === 0}
                          type="number"
                          label="Собранная сумма"
                          onChange={(e) => {
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: { ...prev[item.id], quantity: e.target.value },
                            }));
                          }}
                        />
                      </Box>
                      <Box flex={1} sx={{ minWidth: '127px' }}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          select
                          label="Коэф-т сложности"
                          defaultValue={selected[item.id].coef}
                          value={selected[item.id].coef}
                          sx={{ width: '100%', svg: { color: '#0278FF' } }}
                          inputProps={{ style: { width: '100%' } }}
                          SelectProps={{ IconComponent: KeyboardArrowDown }}
                          onChange={(e) => {
                            setSelected((prev) => {
                              return {
                                ...prev,
                                [item.id]: { ...prev[item.id], coef: e.target.value },
                              };
                            });
                          }}
                        >
                          {Array(item.maxCoef || 7)
                            .fill(null)
                            .map((_, i) => (
                              <MenuItem key={'select' + i + item.id} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Box>
                      <Box flex={1}>
                        <TextField
                          label="Скидка %"
                          value={selected[item.id].discount}
                          type="number"
                          disabled={selected[item.id].quantity === 0}
                          fullWidth
                          onChange={(e) =>
                            setSelected((prev) => {
                              return {
                                ...prev,
                                [item.id]: {
                                  ...prev[item.id],
                                  discount:
                                    +e.target.value > 100
                                      ? 100
                                      : +e.target.value < 0
                                      ? 0
                                      : e.target.value,
                                },
                              };
                            })
                          }
                        />
                      </Box>
                      <Box flex={1}>
                        <TextField
                          label="Стоимость"
                          disabled={selected[item.id].quantity === 0}
                          fullWidth
                          value={calculateItem(selected[item.id]).toFixed(2)}
                        />
                      </Box>
                    </Box>
                  );
                }
                if (item.id === 'managerial-accounting') {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        display: item.name.toUpperCase().includes(filter.toUpperCase())
                          ? 'flex'
                          : 'none',
                      }}
                      gap={2}
                    >
                      <Box width="320px">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selected[item.id].quantity > 0}
                                onChange={(_, val) => {
                                  setSelected((prev) => ({
                                    ...prev,
                                    [item.id]: { ...prev[item.id], quantity: Number(val) },
                                  }));
                                }}
                                value={item.id}
                              />
                            }
                            sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: '100%' }}
                              >
                                {item.name}
                                {item.suggestion && <Pop suggestion={item.suggestion} />}
                              </Box>
                            }
                          ></FormControlLabel>
                        </FormGroup>
                      </Box>
                      <Box flex={1} sx={{ minWidth: '115px' }}>
                        <TextField
                          label="Базовая стоимость"
                          type="number"
                          disabled={selected[item.id].quantity === 0}
                          value={selected[item.id].price}
                          onChange={(e) =>
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: {
                                ...prev[item.id],
                                price: e.target.value.replace(/\0-9/g, ''),
                              },
                            }))
                          }
                        />
                      </Box>

                      <Box flex={1}>
                        <TextField
                          fullWidth
                          value={selected[item.id].quantity}
                          disabled={selected[item.id].quantity === 0}
                          type="number"
                          label="Кол-во"
                          onChange={(e) => {
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: {
                                ...prev[item.id],
                                quantity:
                                  +e.target.value > 100
                                    ? 100
                                    : +e.target.value < 0
                                    ? 0
                                    : e.target.value,
                              },
                            }));
                          }}
                        />
                      </Box>
                      <TextField
                        select
                        label="Коэф-т сложности"
                        disabled={selected[item.id].quantity === 0}
                        defaultValue={selected[item.id].coef}
                        value={selected[item.id].coef}
                        sx={{ width: '100%', svg: { color: '#0278FF' }, flex: 1 }}
                        inputProps={{ style: { width: '100%' } }}
                        SelectProps={{ IconComponent: KeyboardArrowDown }}
                        onChange={(e) => {
                          setSelected((prev) => {
                            return {
                              ...prev,
                              [item.id]: { ...prev[item.id], coef: e.target.value },
                            };
                          });
                        }}
                      >
                        {Array(item.maxCoef || 7)
                          .fill(null)
                          .map((_, i) => (
                            <MenuItem key={'select' + i + item.id} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                      </TextField>
                      <Box flex={1}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          label="Скидка %"
                          value={selected[item.id].discount}
                          type="number"
                          fullWidth
                          onChange={(e) =>
                            setSelected((prev) => {
                              return {
                                ...prev,
                                [item.id]: {
                                  ...prev[item.id],
                                  discount:
                                    +e.target.value > 100
                                      ? 100
                                      : +e.target.value < 0
                                      ? 0
                                      : e.target.value,
                                },
                              };
                            })
                          }
                        />
                      </Box>
                      <Box flex={1}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          label="Стоимость"
                          fullWidth
                          value={calculateItem(selected[item.id]).toFixed(2)}
                        />
                      </Box>
                    </Box>
                  );
                }
                return (
                  <Box
                    key={item.id}
                    sx={{
                      display: item.name.toUpperCase().includes(filter.toUpperCase())
                        ? 'flex'
                        : 'none',
                    }}
                    gap={2}
                  >
                    <Box width="320px">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selected[item.id].quantity > 0}
                              onChange={(_, val) => {
                                setSelected((prev) => ({
                                  ...prev,
                                  [item.id]: { ...prev[item.id], quantity: Number(val) },
                                }));
                              }}
                              value={item.id}
                            />
                          }
                          sx={{ '.MuiFormControlLabel-label': { width: '100%' } }}
                          label={
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ width: '100%' }}
                            >
                              {item.name}
                              {item.suggestion && <Pop suggestion={item.suggestion} />}
                            </Box>
                          }
                        ></FormControlLabel>
                      </FormGroup>
                    </Box>
                    {'tariff' in item && (
                      <Box flex={1} sx={{ minWidth: '115px' }}>
                        <TextField
                          disabled={selected[item.id].quantity === 0}
                          label="Сто-сть тарифа"
                          value={selected[item.id].price}
                          FormHelperTextProps={{ sx: { mx: 0, whiteSpace: 'nowrap' } }}
                          type="number"
                          helperText={
                            <Link
                              href="/sales"
                              target="_blank"
                              sx={{
                                color: '#DA0E17',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                            >
                              Узнать сто-ть тарифа
                            </Link>
                          }
                          onChange={(e) =>
                            setSelected((prev) => ({
                              ...prev,
                              [item.id]: { ...prev[item.id], price: e.target.value },
                            }))
                          }
                        />
                      </Box>
                    )}
                    <Box flex={1}>
                      <TextField
                        fullWidth
                        disabled={selected[item.id].quantity === 0}
                        value={selected[item.id].quantity}
                        type="number"
                        label="Кол-во"
                        onChange={(e) => {
                          setSelected((prev) => ({
                            ...prev,
                            [item.id]: {
                              ...prev[item.id],
                              quantity:
                                +e.target.value > 100
                                  ? 100
                                  : +e.target.value < 0
                                  ? 0
                                  : e.target.value,
                            },
                          }));
                        }}
                      />
                    </Box>
                    <Box flex={1} sx={{ minWidth: '127px' }}>
                      <TextField
                        select
                        disabled={selected[item.id].quantity === 0}
                        label="Коэф-т сложности"
                        defaultValue={selected[item.id].coef}
                        value={selected[item.id].coef}
                        sx={{ width: '100%', svg: { color: '#0278FF' } }}
                        inputProps={{ style: { width: '100%' } }}
                        SelectProps={{ IconComponent: KeyboardArrowDown }}
                        onChange={(e) => {
                          setSelected((prev) => {
                            return {
                              ...prev,
                              [item.id]: { ...prev[item.id], coef: e.target.value },
                            };
                          });
                        }}
                      >
                        {Array(item.maxCoef || 7)
                          .fill(null)
                          .map((_, i) => (
                            <MenuItem key={'select' + i + item.id} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Box>
                    <Box flex={1}>
                      <TextField
                        disabled={selected[item.id].quantity === 0}
                        label="Скидка %"
                        value={selected[item.id].discount}
                        type="number"
                        fullWidth
                        onChange={(e) =>
                          setSelected((prev) => {
                            return {
                              ...prev,
                              [item.id]: {
                                ...prev[item.id],
                                discount:
                                  +e.target.value > 100
                                    ? 100
                                    : +e.target.value < 0
                                    ? 0
                                    : e.target.value,
                              },
                            };
                          })
                        }
                      />
                    </Box>
                    <Box flex={1}>
                      <TextField
                        label="Стоимость"
                        disabled={selected[item.id].quantity === 0}
                        fullWidth
                        value={calculateItem(selected[item.id]).toFixed(2)}
                        onChange={() => null}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Stack
            flexDirection="column"
            width="340px"
            sx={{
              gap: '26px',
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 169px)',
              mt: -0.5,
              pt: 0.5,
            }}
          >
            <CalcSelect />
            <TextField
              value={res.toFixed(2)}
              label="Общая стоимость"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CurrencyRuble sx={{ color: '#9FA4BB' }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              select
              value={totalDifficult}
              sx={{ width: '100%', svg: { color: '#0278FF' } }}
              SelectProps={{ IconComponent: KeyboardArrowDown }}
              onChange={(e) => {
                setTotalDifficult(e.target.value);
              }}
              label="Общий коэф-т сложности"
            >
              {Array(7)
                .fill(null)
                .map((_, i) => (
                  <MenuItem key={'select-dif' + i} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              label="Общая скидка"
              value={totalDiscount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Percent sx={{ color: '#9FA4BB' }} />
                  </InputAdornment>
                ),
              }}
              type="number"
              onChange={(e) => {
                setTotalDiscount(
                  +e.target.value > 100
                    ? 100
                    : +e.target.value < 0
                    ? 0
                    : e.target.value.replace(/\0-9/g, ''),
                );
              }}
            />
            <TextField
              label={<Box sx={{ backgroundColor: '#F2F3F8', px: 0.5 }}>Описание для клиента:</Box>}
              multiline
              sx={{ flex: 1, display: 'flex' }}
              value={text}
              InputProps={{
                sx: { flex: 1, alignItems: 'start', fontSize: '10pt' },
              }}
            />
            <Button
              startIcon={<CopyAllOutlined />}
              sx={{ textTransform: 'none', mb: 2 }}
              variant="outlined"
              onClick={() => copyToBuffer(text)}
            >
              Копировать
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Dop;
